<template>
  <div class="form-item-pane">
    <el-form-item label-width="80px" prop="content">
      <el-upload
        class="avatar-uploader"
        action="api/file/upload"
        name="file"
        :show-file-list="false"
        :on-success="uploadSuccess"
      >
      </el-upload>

      <quill-editor
        v-model="model.content"
        :options="option"
        ref="QuillEditor"
        @change="onEditorChange($event)"
      />
    </el-form-item>
  </div>
</template>

<script>
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
  ["blockquote", "code-block"], //引用，代码块
  [{ header: 1 }, { header: 2 }], // 几级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
  [{ script: "sub" }, { script: "super" }], // 下角标，上角标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  [{ direction: "rtl" }], // 文字输入方向
  [{ size: ["small", false, "large", "huge"] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 颜色选择
  [
    {
      font: [
        "SimSun",
        "SimHei",
        "Microsoft-YaHei",
        "KaiTi",
        "FangSong",
        "Arial",
      ],
    },
  ], // 字体
  [{ align: [] }], // 居中
  ["clean"], // 清除样式,
  ["link", "image"], // 上传图片、上传视频
];
export default {
  name: "TabPane3",
  props: { model: Object },
  data() {
    return {
      option: {
        placeholder: "请输入商品详情描述",
        theme: "snow", //主题 snow/bubble
        modules: {
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false,
          },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用element的图片上传组件
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
    };
  },
  methods: {
    onEditorChange(e) {
      this.model.content = e.html;

    },
    uploadSuccess(res) {
      // 获取富文本组件实例
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(
          length,
          "image",
          "/api/" + res.data.path
        );
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        this.$message.error("图片插入失败！");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-item-pane {
  width: 750px;
}
</style>
