<template>
  <el-form
    ref="form"
    class="page"
    label-width="140px"
    :model="model"
    :rules="rules"
  >
    <el-tabs class="tabs" v-model="active">
      <el-tab-pane label="基础设置" name="0">
        <tab-pane-1 :model="model" :rules="rules" />
      </el-tab-pane>
      <el-tab-pane label="价格库存" name="1">
        <tab-pane-2
          :model="model"
          :rules="rules"
          @add-sku="onAddSku"
          @remove-sku="onRemoveSku"
          @change-sku="onChangeSku"
          @isDefaultChange="isDefaultChange"
        />
      </el-tab-pane>
      <el-tab-pane label="商品详情" name="2">
        <tab-pane-3 :model="model" :rules="rules" />
      </el-tab-pane>
    </el-tabs>
    <div class="footer">
      <el-button @click="onPrevious">上一个</el-button>
      <el-button v-loading="loading" type="primary" @click="onSubmit">
        提 交
      </el-button>
      <el-button @click="onNext">下一个</el-button>
    </div>
  </el-form>
</template>

<script>
import request from '@/utils/request';
import tabPane1 from './tabPane1';
import tabPane2 from './tabPane2';
import tabPane3 from './tabPane3';
// import store from "@/store";

export default {
  name: 'Create',
  components: { tabPane1, tabPane2, tabPane3 },
  data() {
    return {
      isFirstTime: true,
      // 激活的选项卡
      active: '0',
      // 是否加载中
      loading: false,
      // 要提交的商品数据
      model: {
        // 名称
        name: undefined,
        // 促销语
        promotion: undefined,
        // 关键词
        keyword: undefined,
        // 主图
        image: '',
        // 品牌
        brand: undefined,
        isIntegralPay: 0,
        isParticipants: 0,
        participantsNumber: 0,
        participantsDuration: 0,
        participantsExpirationTime: '',
        // 商品分类
        classificationGoodsId: undefined,
        // 首页
        isHome: 1,
        activeTag: '',
        isIndexBigPic: 0,
        //规则列表
        skuList: [],
        // 是否上架
        isPutaway: 0,
        // 排序
        sorts: 999,
        // 是否启用多规则
        enable: false,
        // 单规则
        goodsSkuAdd: {
          // 规则名
          name: undefined,
          // 图片
          image: undefined,
          // 副标题
          subheading: undefined,
          // 销售价
          salesPrice: undefined,
          // 划线价
          originalPrice: undefined,
          // 成本价
          costPrice: undefined,
          // 库存
          inventory: undefined,
          maxNumber: 10,
          // 预警库存
          earlyWarning: undefined,
          // 重量（g）
          weight: undefined,
          // 体积（m³）
          volume: undefined,
          integralPay: 0,
          unit: '',
          isDefault: 1
        },
        // 多规格，由规则组成的树形结构
        goodsSpecificationAdd: [],
        // 详情
        content: undefined,
        // 店铺
        storeId: ''
      },
      // 校验规则
      rules: {
        name: [{ required: true, message: '商品名称是必填的' }],
        image: [{ required: true, message: '商品主图是必填的' }],
        storeId: [{ required: true, message: '请选择商品归属店铺' }],
        classificationGoodsId: [{ required: true, message: '商品分类必选的' }],
        goodsSpecificationAdd: [
          { required: true, message: '价格库存是必填的' },
          { required: true, validator: this.validator }
        ],
        isPutaway: [{ required: true, message: '是否上架是必选的' }],
        content: [{ required: true, message: '商品名称是必填的' }]
      }
    };
  },
  async created() {
    await this.getDetails();
  },
  mounted() {},
  methods: {
    getFileList(url) {
      const prefix = '/api/';
      let fileList = [];
      let arr = url.split(',');
      arr.forEach((item, index) => {
        fileList.push({ name: 'pic' + index, url: prefix + item });
      });

      return fileList;
    },
    onAddSku(e) {
      if (e == false) {
        this.$confirm('此操作将删除多规格数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.model.goodsSkuAdd = {
              name: undefined,
              subheading: undefined,
              image: undefined,
              salesPrice: 0,
              originalPrice: undefined,
              costPrice: undefined,
              inventory: 0,
              maxNumber: 10,
              earlyWarning: undefined,
              weight: undefined,
              volume: undefined,
              isDefault: 1,
              integralPay: 0,
              unit: ''
            };
            this.model.enable = false;
            this.model.skuList = [];
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消操作'
            });
          });
      } else {
        this.model.skuList.push(e);
        this.createTree();
      }
    },
    onRemoveSku(index) {
      this.model.skuList.splice(index, 1);
      this.createTree();
    },
    onChangeSku({ key, values, index }) {
      this.model.skuList[index][key] = values;
      this.createTree();
    },

    //默认商品变化
    isDefaultChange(row) {
      this.model.goodsSpecificationAdd.forEach(item => {
        this.mapEdit(item, row);
      });
    },
    //遍历树修改值
    mapEdit(obj, row) {
      if (obj.children) {
        obj.children.forEach(item => {
          this.mapEdit(item, row);
        });
      } else {
        if (obj.subheading !== row.subheading) {
          obj.goodsSkuAdd.isDefault = 1;
        } else {
          this.$message.error('请确保有默认商品！');
          obj.goodsSkuAdd.isDefault = 0;
        }
      }
    },

    createTree() {
      this.isFirstTime = true;
      const tree = this.model.skuList[0].values.map(value => {
        return this.mapper(this.model.skuList[0].name, value, 1, '');
      });

      this.model.goodsSpecificationAdd = tree;
    },

    mapper(name, value, deep, previousValue) {
      let subheading = previousValue + value;
      const last = deep >= this.model.skuList.length;
      const item = {
        name,
        value,
        subheading,
        goodsSkuAdd: {
          name: undefined,
          subheading,
          image: undefined,
          salesPrice: undefined,
          originalPrice: undefined,
          costPrice: undefined,
          inventory: undefined,
          maxNumber: 10,
          earlyWarning: undefined,
          weight: undefined,
          volume: undefined,
          isDefault: this.isFirstTime ? 0 : 1,
          integralPay: 0,
          unit: ''
        }
      };
      //  不是最后一层,删除goodsSkuAdd
      if (!last) {
        subheading += ' ';
        delete item.goodsSkuAdd;
        const next = this.model.skuList[deep++];
        const children = next.values.map(val =>
          this.mapper(next.name, val, deep, subheading)
        );

        return Object.assign(item, { subheading, children });
      } else {
        // 最后一层

        this.isFirstTime = false;

        return item;
      }
    },

    getTreeVal(str) {
      for (
        let index = 0;
        index < this.model.goodsSpecificationAdd.length;
        index++
      ) {
        const element = this.model.goodsSpecificationAdd[index];

        let res = this.recursionTree(element, str);
        return res;
      }
    },

    recursionTree(obj, str) {
      if (obj.children) {
        this.recursionTree(obj.children, str);
      } else {
        let res = obj.goodsSpecificationAdd.find(item => {
          return item.subheading == str;
        });

        return res;
      }
    },

    //存在一种情况是开启多规格，但是只加了一个
    checkIsMuchSku(data) {
      try {
        if (data.length > 1) {
          return true;
        } else {
          if (data[0].subheading !== '') {
            return true;
          } else {
            return false;
          }
        }
      } catch (error) {
        return false;
      }
    },
    // 加载详情数据
    async getDetails() {
      try {
        if (!this.$route.query.id) {
          return;
        }
        this.loading = true;
        const { data } = await request.get('/goodsBasicSetting/detail', {
          params: { id: this.$route.query.id }
        });

        this.model = data;
        this.model.fileList = this.getFileList(data.image);
        //单规格的
        let checkRes = this.checkIsMuchSku(data.goodsSpecificationAdd);
        if (!checkRes) {
          this.model.goodsSkuAdd = data.goodsSpecificationAdd[0].goodsSkuAdd;
        } else {
          this.model.enable = true;
          this.model.goodsSpecificationAdd = data.goodsSpecificationAdd;

          this.model.skuList = data.skuList;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // 价格库存校验器
    validator(rule, values, callback) {
      let error = '';
      values.forEach(priceAndInventory);

      // 递归校验，只判断最后一级
      function priceAndInventory(item, index) {
        if (!item.children) {
          const { salesPrice, inventory } = item.goodsSkuAdd;
          if (!salesPrice || salesPrice == '') {
            error = `第${index + 1}行${item.subheading}销售价不能为空`;
          } else if (!inventory) {
            error = `第${index + 1}行${item.subheading}库存不能为空`;
          }
          return;
        }
        item.children.forEach(priceAndInventory);
      }

      // 有错误
      if (error) {
        callback(new Error(error));
      } else {
        callback();
      }
    },
    // 上一步
    onPrevious() {
      let value = parseInt(this.active);
      if (this.active > 0) {
        value--;
        this.active = `${value}`;
      }
    },
    // 下一步
    onNext() {
      let value = parseInt(this.active);
      if (this.active < 2) {
        value++;
        this.active = `${value}`;
      }
    },

    // 提交表单
    async onSubmit() {
      const { model, $refs, $message } = this;
      try {
        this.loading = true;
        // await $refs.form.validate();

        $refs.form.validate(valid => {
          if (valid) {
            console.log('submit');
          } else {
            $message.error('请填写必要的商品信息');
            throw new Error('');
          }
        });

        if (model.isParticipants == 1) {
          if (
            model.participantsNumber == 0 ||
            model.participantsDuration == 0 ||
            model.participantsExpirationTime == ''
          ) {
            $message.error('请填写必要的拼团信息');
            throw new Error('');
          }
        }

        //允许使用积分，但是积分的总数大于商品的价格*10
        // if (model.isIntegralPay == 1) {
        //   if (model.participantsNumber == 0 || model.participantsDuration == 0 || model.participantsExpirationTime == "") {
        //     $message.error("请填写必要的拼团信息");
        //     throw new Error('')

        //   }

        // }

        // 如查是单规则
        if (!model.enable) {
          model.goodsSpecificationAdd = [];
          model.goodsSkuAdd.image =
            this.model.image.indexOf(',') !== -1
              ? this.model.image.split(',')[0]
              : this.model.image;
          model.goodsSkuAdd.name = this.model.name;
          model.goodsSkuAdd.isDefault = 0;

          model.goodsSpecificationAdd.push({
            name: this.model.name,
            subheading: '',
            value: this.model.name,
            goodsSkuAdd: model.goodsSkuAdd
          });
        }
        model.goodsSkuAdd = '';

        if (this.$route.query.id) {
          let res = await request.post('/goodsBasicSetting/update', model);
          if (res.code == 200) {
            this.$message.success(res.message);
          }
        } else {
          let res = await request.post('/goodsBasicSetting/create', model);
          if (res.code == 200) {
            this.$message.success(res.message);
          }
        }

        await this.$router.replace({ path: '/goods/list' });
      } catch (error) {
        if (!error) {
          $message.error('请填写必要的商品信息');
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  margin: 20px;
  padding-bottom: 120px;

  .tabs {
    flex: 1 0;
    padding: 20px;
    background-color: white;
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    border-top: thin solid lightblue;
    padding: 20px;
    background-color: white;
  }
}
</style>
