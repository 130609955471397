<template>
  <div class="form-item-pane">
    <el-form-item label="商品名称" prop="name">
      <el-input v-model="model.name" placeholder="请输入商品名称，不能超过60个字符" />
    </el-form-item>
    <el-form-item label="归属店铺" prop="storeId" v-if="isAdmin">
      <el-select v-model="model.storeId" placeholder="请选择店铺">
        <el-option v-for="item in storeList" :key="item.id" :label="item.storeName" :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="促销语" prop="promotion">
      <el-input v-model="model.promotion" placeholder="请输入促销语，不能超过100个字符" />
    </el-form-item>
    <el-form-item label="活动标签" prop="activeTag">
      <el-input v-model="model.activeTag" placeholder="活动标签" @change="setActiveTagStr" />
    </el-form-item>

    <el-form-item label="是否拼团" prop="isParticipants">
      <el-switch v-model="model.isParticipants" active-color="#13ce66" inactive-color="#ccc" :active-value="1"
        :inactive-value="0"></el-switch>
    </el-form-item>
    <el-form-item label="成团人数" prop="participantsNumber" v-show="model.isParticipants == 1">
      <el-input v-model.number="model.participantsNumber" placeholder="请输入成团人数" :min="1"> <template
          slot="append">人</template></el-input>
    </el-form-item>
    <el-form-item label="拼团时长" prop="participantsDuration" v-show="model.isParticipants == 1">
      <el-input v-model.number="model.participantsDuration" placeholder="请输入拼团持续时长（单位分钟）" :min="1"> <template
          slot="append">分钟</template></el-input>
    </el-form-item>

    <el-form-item label="开团结束时间" prop="participantsExpirationTime" v-show="model.isParticipants == 1">
      <el-date-picker v-model="model.participantsExpirationTime" :style="{ 'width': '100%' }" type="datetime"
        placeholder="开团结束时间(商品存在时间，到期后商品无法下单)" value-format="yyyy-MM-dd HH:mm:ss">
      </el-date-picker>

    </el-form-item>

    <el-form-item label="关键词" prop="keyword">
      <el-input v-model="model.keyword" placeholder="请输入关键词，不能超过100个字符" />
    </el-form-item>
    <el-form-item label="商品主图" prop="image">
      <el-upload action="api/file/upload" list-type="picture-card" :limit="5" :file-list="model.fileList"
        :on-success="onUploadSuccess" :on-remove="onUploadRemove">
        <i class="el-icon-plus"></i>
      </el-upload>
    </el-form-item>
    <el-form-item label="品牌" prop="brand">
      <el-select v-model="model.brand" placeholder="请选择品牌">
        <el-option v-for="item in brands" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="商品分类" prop="classificationGoodsId">
      <el-cascader v-model="model.classificationGoodsId" :options="categories" :props="cascaderProps"
        placeholder="请选择商品分类" />
    </el-form-item>
    <el-form-item label="首页推荐" prop="isHome">
      <el-radio-group v-model="model.isHome">
        <el-radio :label="0">推荐</el-radio>
        <el-radio :label="1">不推荐</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="首页大图" prop="isIndexBigPic">
      <el-radio-group v-model="model.isIndexBigPic">
        <el-radio :label="0">否</el-radio>
        <el-radio :label="1">是</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="是否上架" prop="isPutaway">
      <el-radio-group v-model="model.isPutaway">
        <el-radio :label="0">立刻上架</el-radio>
        <el-radio :label="1">放入仓库</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="积分支付" prop="isIntegralPay">
      <el-radio-group v-model="model.isIntegralPay">
        <el-radio :label="0">禁止</el-radio>
        <el-radio :label="1">允许</el-radio>
      </el-radio-group>
    </el-form-item>
    
    <el-form-item label="排序" porp="sorts">
      <el-input v-model.number="model.sorts" placeholder="数量越小越靠前"></el-input>
    </el-form-item>
  </div>
</template>

<script>
import request from "@/utils/request";
import store from "@/store";

export default {
  name: "TabPane1",
  props: { model: Object },
  data() {
    return {
      // 填充品牌
      brands: [],
      storeList: [],
      cascaderProps: {
        label: "name",
        value: "id",
        expandTrigger: "hover",
        emitPath: false,
      },
      // 填充分类
      categories: [],
      isAdmin: false,
    };
  },
  async created() {
    await this.getBrands();
    await this.getCategories();
    this.getStoreList();
    this.getCurUserRole();
  },
  mounted() {


    if (this.$cookie.get("activeTag") !== '') {

      this.model.activeTag = this.$cookie.get("activeTag");
    }


  },


  methods: {


    setActiveTagStr() {

      this.$cookie.set("activeTag", this.model.activeTag, { expires: 30 });
    },

    handleChange(file, fileList, scope) {

      console.log(file, fileList, scope);
    },

    getCurUserRole() {
      const userInfo = store.getters.userInfo;

      let arr = [];
      userInfo.roleList.forEach((element) => {
        arr.push(element.name);
      });

      if (arr.indexOf("admin") !== -1 || arr.indexOf("ptadmin") !== -1) {
        this.isAdmin = true;
      } else if (arr.indexOf("storeAdmin") !== -1) {
        this.isAdmin = false;
        this.model.storeId = userInfo.store.id;

      }
    },

    async getStoreList() {
      let res = await request.get("/store", {
        params: {
          pageSize: 500,
        },
      });
      if (res.code == 200) {
        this.storeList = res.data.data;
      }
    },
    // 上传成功
    onUploadSuccess(response, file, fileList) {
      this.model.fileList = fileList
      let imageArr = [];
      fileList.forEach((item) => {
        if (item.response) {
          imageArr.push(item.response.data.path);
        } else {
          imageArr.push(item.url.replace("/api/", ""));
        }
      });
      this.model.image = imageArr.join(",");



    },
    // 删除上传的图片
    async onUploadRemove(file, fileList) {
      this.model.fileList = fileList
      let imageArr = [];
      fileList.forEach((item) => {
        if (item.response) {
          imageArr.push(item.response.data.path);
        } else {
          imageArr.push(item.url.replace("/api/", ""));
        }
      });
      this.model.image = imageArr.join(",");
    },
    // 获得品牌填充数据
    async getBrands() {
      try {
        this.loading = true;
        const { data } = await request.get("/systemDataDictionaryItem", {
          params: {
            systemDataDictionaryId: "1595246363145887746",
            pageSize: 500,
          },
        });
        this.brands = data.data.map((item) => {
          return {
            label: item.parameterName,
            value: item.id,
          };
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // 获得分类填充数据
    async getCategories() {
      try {
        this.loading = true;
        const { data } = await request.get("/classificationGoods");
        this.categories = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-item-pane {
  width: 650px;
}
</style>
